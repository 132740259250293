html,
body,
#root,
#root {
  height: 100%;
  background: #f5f5f5;
}

body {
  text-rendering: optimizeSpeed;
  margin: 0;
  text-decoration-skip-ink: none;
}

.ps {
  overflow: scroll !important;
}

.ps::-webkit-scrollbar {
  display: none;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  z-index: 1290;
}

a {
  color: #000000;
  text-decoration: underline;
  text-decoration-color: #000000;
}

p {
  margin-top: 0;
}

.pg-viewer-wrapper .pg-viewer {
  background: #fff;
}

.pg-viewer-wrapper .pdf-canvas > canvas {
  border: 2px solid #e7eef3;
}

.photo-viewer-container {
  height: 100% !important;
}

.photo-viewer-container > img {
  height: 100% !important;
  width: 100% !important;
}

.rst__rowContents {
  padding-right: 0;
}

.root-layout {
  height: 100%;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.Pane {
  overflow: hidden;
  overflow-y: auto;
}

.unsupported-message {
  padding: 0;
}

@font-face {
  font-family: 'e-Ukraine';
  font-weight: 400;
  /* font-display: swap; */
  src:
    local('e-Ukraine'),
    local('e-Ukraine-Regular'),
    url('../fonts/e-Ukraine-Regular.woff') format('woff');
}

@font-face {
  font-family: 'e-Ukraine';
  font-weight: 300;
  /* font-display: swap; */
  src:
    local('e-Ukraine'),
    local('e-Ukraine-Light'),
    url('../fonts/e-Ukraine-Light.woff') format('woff');
}

@font-face {
  font-family: 'e-Ukraine';
  font-weight: 500;
  /* font-display: swap; */
  src:
    local('e-Ukraine'),
    local('e-Ukraine-Medium'),
    url('../fonts/e-Ukraine-Medium.woff') format('woff');
}

@-webkit-keyframes granimate {
  0%,
  100% {
    background-position: 0 25%;
  }

  25%,
  75% {
    background-position: 50% 50%;
  }

  50% {
    background-position: 100% 100%;
  }
}

@keyframes granimate {
  0%,
  100% {
    background-position: 0 25%;
  }

  25%,
  75% {
    background-position: 50% 50%;
  }

  50% {
    background-position: 100% 100%;
  }
}

#cricket-button-container {
  right: 20px !important;
}

@media (max-width: 767px) {
  #cricket-button-container {
    right: 0px !important;
    bottom: 30px !important;
  }
}

.mt20d {
  margin-top: -20px;
}

.info-block {
  display: inline-flex;
  background: #fff4d7;
  padding: 30px 52px 34px 18px;
  margin-bottom: 50px;
  vertical-align: top;
  margin-top: 0;
  line-height: 24px;
}

.info-block p {
  margin: 0;
}

.info-block-icon {
  font-size: 38px;
  padding: 0px 17px 0px 0px;
  margin: 0px;
}

.bank-block {
  display: inline-flex;
  background: #fff4d7;
  padding: 30px 52px 34px 18px;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.02em;
  align-items: center;
  text-align: left;
}

.bank-block {
  display: inline-flex;
  background: #fff4d7;
  padding: 30px 52px 34px 18px;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.02em;
  align-items: center;
  text-align: left;
}
.fop-title {
  font-size: 20px;
  line-height: 24px;
}

.fop-date-descr {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 48px;
}

.user-info-fop {
  margin-top: 40px;
  margin-bottom: 60px;
  padding: 2px;
  position: relative;
  animation: 10s ease 0s infinite normal none running granimate;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-size: 200% 300%;
  background-image: linear-gradient(217deg, rgba(255, 0, 0, 0.8), rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%);
}

.user-info-fop-content {
  padding: 26px 32px;
  background: rgb(255, 255, 255);
}

.user-info-fop-content > div:last-of-type > p:last-child {
  margin-bottom: 0;
}

.user-info-fop-content > p:last-child {
  margin-bottom: 0;
}

.fop-blocked {
  padding: 20px 10px 80px 20px;
  color: rgb(0, 0, 0);
}

.fop-blocked-title {
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 36px;
}

.fop-blocked-descr {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 26px;
}

.tov-block-title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 47px;
}

.tov-block-title-big {
  font-size: 38px;
  line-height: 40px;
  letter-spacing: -0.02em;
  margin-bottom: 35px;
}

.tov-info-block {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 2px;
  position: relative;
  animation: 10s ease 0s infinite normal none running granimate;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-size: 200% 300%;
  background-image: linear-gradient(217deg, rgba(255, 0, 0, 0.8), rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%);
}

.tov-info-block-cont {
  padding: 22px 32px;
  background: #fff;
}

.fop-popup-group-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
}

.fop-popup-group-title-margin {
  margin-bottom: 44px;
  margin-top: 72px;
}

.fop-layout-gpoup-title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 44px;
  margin-top: 39px;
  font-weight: normal;
}

.fop-layout-main-title {
  font-weight: normal;
  font-size: 38px;
  line-height: 40px;
  margin-top: 80px;
}

.remove-mb-last div:last-of-type p:last-of-type {
  margin-bottom: 0px;
}

.message_label_wrapp {
  padding-left: 40px;
}

@media (max-width: 600px) {
  .tov-block-title-big {
    font-size: 20px;
    max-width: 80%;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .MuiTableCell-paddingNone:empty {
    padding: 0px !important;
    display: none;
  }

  .info-block {
    margin-bottom: 0;
  }

  .fop-title {
    margin-top: 10px;
    font-size: 15px;
  }

  .fop-date-descr {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 17px;
    top: -10px;
    position: relative;
  }

  .user-info-fop {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .user-info-fop-content {
    padding: 16px;
  }

  .fop-blocked {
    padding: 0;
  }

  .fop-blocked-title {
    font-size: 25px;
    line-height: 33px;
    margin-bottom: 18px;
  }

  .fop-blocked-descr {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 18px;
  }

  .tov-block-title {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 30px;
  }

  .tov-info-block {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .tov-info-block-cont {
    padding: 16px;
    background: #fff;
  }

  .fop-popup-group-title {
    font-size: 16px;
    line-height: 24px;
  }

  .fop-layout-main-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .fop-layout-gpoup-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .fop-popup-group-title-margin {
    margin-bottom: 0;
    margin-top: 20px;
  }

  .pg-viewer-wrapper .pdf-canvas > canvas {
    width: 100%;
  }

  .message_label_wrapp {
    padding-left: 16px;
  }
}

.main_link {
  color: #fff;
  border-bottom: 1px solid #fff;
  text-decoration: none;
}

.data-grid-container .data-grid .cell.selected {
  box-shadow: none;
  background-color: rgb(33 133 208 / 15%);
  overflow-x: visible;
}

.dataTable-cell > div > div::before {
  border-bottom: 0;
}

.data-grid-container .data-grid .cell.read-only {
  vertical-align: middle;
}

.data-grid-container .data-grid .cell.datatable-cell.read-only {
  background: #ffffff;
  padding: 0 8px;
}

/* modern calendar */

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  background-color: rgb(58, 154, 230);
  color: #fff;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  display: none;
}

.datepicker_wrapper
  .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  background-color: rgb(58, 154, 230);
  color: #fff;
}

.datepicker_wrapper
  .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  display: none;
}

.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
  background-color: rgb(58, 154, 230);
  word-break: break-word;
}

.datepicker_wrapper .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.datepicker_wrapper .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
  background-color: rgb(58, 154, 230);
}

.Calendar {
  width: 260px;
  height: 280px;
  min-height: 260px;
  border-radius: 0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.Calendar > :not(.Calendar__footer) button {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 6px;
  border-radius: 0;
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(
    .-selected
  ):hover {
  background: #e7eef3;
  border-radius: 0;
  color: #000;
}

.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background: #000000;
  color: #fff;
}

.Calendar__weekDay {
  color: #000;
  font-size: 12px;
}

.Calendar__section.-shown {
  padding: 0.5em 2.2em;
}

.Calendar__header {
  padding: 0;
  padding: 15px 32px;
  margin-bottom: 12px;
}

.Calendar__day.-ltr {
  height: 24px !important;
  width: 24px !important;
  font-size: 12px;
  border-radius: 0;
  padding: 0px;
  min-height: unset;
  font-weight: 300;
  margin-right: 6px;
  margin-bottom: 0;
  margin-left: 1px;
}

.Calendar__weekRow {
  margin-bottom: 6px;
}

.Calendar__weekDays {
  padding: 0 24px;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  font-weight: 300;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(
    .-selectedBetween
  ).Calendar__day.-disabled {
  background-color: rgb(58, 154, 230) !important;
  color: #fff !important;
  opacity: 0.4;
}

.Calendar__monthArrowWrapper.-right {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  padding: 0;
}

.Calendar__monthArrowWrapper.-left {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  padding: 0;
}

.Calendar__monthArrow {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 12L19 12M19 12L12.5839 18M19 12L12.5839 6" stroke="black" stroke-width="2"/></svg>');
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #fff inset;
  -webkit-box-shadow: 0 0 0 30px #fff inset;
}

p.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  transform-origin: top left;
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
  top: 0;
  left: 0;
  position: absolute;
}

.ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'Вкажіть посилання:';
}

.ql-tooltip.ql-flip::before {
  content: 'Відкрити URL:';
}

.ql-snow .ql-tooltip a.ql-action::after {
  content: 'Редагувати';
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: 'Зберегти';
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: 'Видалити';
}

.fc .fc-button-primary {
  background-color: #000;
}

.fc .fc-toolbar-title {
  font-weight: 400;
}

.div-flex {
  display: flex;
}

.fc .fc-col-header-cell-cushion {
  font-weight: 400;
  text-decoration: none;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events,
.fc .fc-timegrid-slot {
  overflow: hidden;
}

.fc .fc-toolbar-title {
  font-size: 1.75em;
  margin: 0px;
  font-size: 25px;
}

.fc .fc-button {
  font-size: 0.9375rem;
}
