@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src:
    local("Roboto"),
    local("Roboto-Regular"),
    url("../fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src:
    local("Roboto"),
    local("Roboto-Medium"),
    url("../fonts/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Roboto Mono";
  font-weight: 400;
  src:
    local("Roboto Mono"),
    local("Roboto Mono Regular"),
    url("../fonts/RobotoMono-Regular.woff2") format("woff2");
}
